<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        The formaldehyde content of a pesticide was determined by weighing
        <number-value :value="liquidSample" unit="\text{g}" />
        of the liquid sample into a flask containing
        <chemical-latex content="70.0 mL" />
        of
        <chemical-latex content="0.1101 M NaOH" />
        and
        <chemical-latex content="70 mL" />
        of
        <chemical-latex content="3\% H2O2\text{.}" />
        Upon heating, the following reaction occurred:
      </p>
      <p>
        <chemical-latex content="OH- + HCHO + H2O2 -> HCOO- + 2H2O" />
      </p>
      <p>
        After cooling, the excess base was titrated with
        <chemical-latex content="41.46 mL" />
        of
        <chemical-latex content="0.0715 M HCl." />
        Calculate the percentage of
        <chemical-latex content="HCHO" />
        in the sample.
      </p>

      <calculation-input
        v-model="inputs.studentAnswer"
        append-text="$\text{\%}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import StembleLatex from '@/tasks/components/StembleLatex';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';

export default {
  name: 'ChemUPEI2210A5_Q4',
  components: {
    ChemicalLatex,
    CalculationInput,
    NumberValue,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        studentAnswer: null,
      },
    };
  },
  computed: {
    liquidSample() {
      return this.taskState.getValueBySymbol('liquidSample').content;
    },
  },
};
</script>
